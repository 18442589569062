/**
 * Parses whether a URL is valid
 * @param url
 * @returns
 */
export const isValidImage = (url?: string): boolean => {
    if (url) {
        return url.startsWith('https') || url.startsWith('Products');
    } else {
        return false;
    }
};
